import React from 'react';
import Layout from 'Layout';
import { Provider } from 'react-redux';
import store from 'lib/store';
import './index.css';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss';
import { MantineProvider, createTheme } from '@mantine/core';

// This is used to store gclid (Google Click ID) in cookie.
if (typeof window !== 'undefined') {
  const params = new URLSearchParams(window.location.search);
  const gclid = params.get('gclid');
  if (gclid) {
    document.cookie = `gclid=${gclid};path=/;max-age=${90 * 24 * 60 * 60}`;
  }
}

const theme = createTheme({
  breakpoints: {
    sm: '0em',
    md: '46.5em',
    lg: '80em',
    xl: '90em',
  },
});

ReactDOM.createRoot(document.getElementById('react-app')!).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <Provider store={store}>
        <Layout />
      </Provider>
    </MantineProvider>
  </React.StrictMode>
);
